import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'edit-tax-group-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class EditTaxGroupDialog extends Vue {
  @Ref('edit-tax-form')
  private refForm!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Watch('dialog')
  onUpdateDialog(newv: boolean) {
    this.refForm?.resetValidation();
    this.fromDateTime = this.formatDate(this.value.fromDateTime);
    this.toDateTime = this.formatDate(this.value.toDateTime);
  }
  @Watch('value.fromDateTime')
  onUpdateFromDateTime(newV: any) {
    if (DateUtils.isDate(newV, 'iso')) this.fromDateTime = this.formatDate(this.value.fromDateTime);
  }
  @Watch('value.toDateTime')
  onUpdateToDateTime(newV: any) {
    if (DateUtils.isDate(newV, 'iso')) this.toDateTime = this.formatDate(this.value.toDateTime);
  }
  @Prop({ default: () => {} })
  private value!: any;
  fromDateTime?: string = '';
  toDateTime?: string = '';
  menu1 = false;
  menu2 = false;
  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    if (await this.refForm?.validate()) this.$emit('click:update', this.value);
  }

  parseDate(str: any) {
    return DateUtils.parseDate(str, this.$i18n.locale);
  }

  formatDate(str: any) {
    return DateUtils.isoDateToScreenDateWithLocale(str, this.$i18n.locale, true);
  }
  get dateHint() {
    return DateUtils.getFormatRepresent(this.$i18n.locale);
  }
  get locale() {
    return this.$i18n.locale == 'de' ? 'de-DE' : 'em-US';
  }
  get dateRules() {
    return [
      (v: any) => DateUtils.isDate(v, this.$i18n.locale) || this.$i18n.t('messages.is_date', { locale: this.dateHint }),
    ];
  }
  async onBlurFromDateTime() {
    const isValid = await (this.$refs?.fromDateTime as any).validate();
    if (!isValid) return;
    this.value.fromDateTime = this.parseDate(this.fromDateTime);
  }
  async onBlurToDateTime() {
    const isValid = await (this.$refs?.toDateTime as any).validate();
    if (!isValid) return;
    this.value.toDateTime = this.parseDate(this.toDateTime);
  }
}
