import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import EditTaxGroupDialog from './../edit-tax-group-dialog/edit-tax-group-dialog.vue';
import allocationTaxGroup, { AllocationTaxGroup } from '@/shared/model/allocationTaxGroup';
import { AllocationTaxSetting } from '@/shared/model/allocationTaxSetting';
import allocationTaxGroupsImportSetting, {
  AllocationTaxGroupsImportSetting,
} from '@/shared/model/allocationTaxGroupsImportSetting';
import { Organisation } from '@/shared/model/organisation';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';

const name = 'tax-group-view';
const logger = new Logger(name);
const allocationTaxGroupModule = namespace('allocationTaxGroup');
const allocationTaxGroupsImportSettingModule = namespace('allocationTaxGroupsImportSetting');
const allocationTaxSettingModule = namespace('allocationTaxSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EditTaxGroupDialog, D4ySwitch } })
export default class TaxGroupView extends Vue {
  @allocationTaxGroupModule.Action('updateAllocationTaxGroup')
  private updateAllocationTaxGroup!: any;

  private inProgressUpdateAllocationTaxGroupsImportSetting: any = false;
  @allocationTaxGroupsImportSettingModule.Action('updateAllocationTaxGroupsImportSetting')
  private updateAllocationTaxGroupsImportSetting!: any;

  @allocationTaxGroupModule.Action('getAllocationTaxSettings')
  private getAllocationTaxSettings!: any;
  get allocationTaxSettings(): OdataItems<AllocationTaxSetting> {
    return (
      this.model.taxSettings ?? {
        items: [],
        total: 0,
        isLoading: false,
        searchParams: {
          dataOption: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['sPercent'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
          },
          filter: '',
          orClauseFieldsIds: ['sPercent', 'sCode', 'Type'],
          orClauseFieldsIdsIgnoreCase: ['sPercent', 'sCode', 'Type'],
        },
      }
    );
  }

  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  @PropSync('value', { default: () => allocationTaxGroup.parse({}) })
  private model!: AllocationTaxGroup;
  @Watch('model')
  onModelUpdate(newV: AllocationTaxGroup, oldV: AllocationTaxGroup) {
    this.reinitImportSettings();
  }

  files = [];

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('percent'), value: 'percent' },
      { text: this.$t('code'), value: 'code' },
      { text: this.$t('type'), value: 'type' },
    ];
    return headers;
  }
  clickEditGroup() {
    this.openDialog(this.model);
  }
  doUpdateAllocationTaxGroup(item: AllocationTaxGroup) {
    this.updateAllocationTaxGroup(item).then((result: any) => {
      if (result.isValid) {
        Object.assign(this.model, item);
        this.reinitImportSettings();
      }
    });
  }
  async OnChangeClearBeforeImport(newv: any, oldv: any) {
    this.inProgressUpdateAllocationTaxGroupsImportSetting = true;
    this.model.allocationTaxGroupsImportSetting.clearBeforeImport = newv;
    await this.updateAllocationTaxGroupsImportSetting(this.model.allocationTaxGroupsImportSetting)
      .then((result: any) => {
        this.model.allocationTaxGroupsImportSetting.id = result.id;
      })
      .catch(() => {
        this.model.allocationTaxGroupsImportSetting.clearBeforeImport = oldv;
      })
      .finally(() => {
        this.inProgressUpdateAllocationTaxGroupsImportSetting = false;
      });
  }
  //#region Dialog logic
  dialogAddEdit = {
    show: false,
    model: allocationTaxGroup.parse({}),
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  dialogAddEditOnUpdate(item: any) {
    this.doUpdateAllocationTaxGroup(item);
    this.dialogAddEdit.show = false;
  }

  private async openDialog(item: any) {
    this.dialogAddEdit.model = { ...item };
    this.dialogAddEdit.show = true;
  }
  //#endregion
  //#region uploadfile
  @allocationTaxSettingModule.Action('uploadAllocationTaxSetting')
  private uploadAllocationTaxSetting!: any;
  private currentFile: string | null = null;
  private currentError: string = '';
  progress = 0;
  selectFile(file: any) {
    this.progress = 0;
    this.currentFile = file;
    this.currentError = '';
  }
  get isVisibleUploadBtn(): boolean {
    if (this.currentFile != null && this.progress == 0) return true;
    if (this.currentFile != null && this.currentError != '' && this.progress == 100) return true;
    return false;
  }
  async upload() {
    if (!this.currentFile) {
      return;
    }

    await this.uploadAllocationTaxSetting({
      fileForImport: this.currentFile,
      allocationTaxGroupsId: this.model.id,
      onUploadProgress: (event: any) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      },
    })
      .then((result: any) => {
        this.reloadData();
      })
      .catch((e: any) => {
        this.currentError = e?.errors.join(',');
      });
  }
  //#endregion

  //#region search update
  @Watch('allocationTaxSettings.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }
  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('allocationTaxSettings.searchParams.dataOption.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      this.reloadData();
    }
  }
  @Watch('allocationTaxSettings.searchParams.dataOption.sortDesc', { deep: true })
  public onOptionsSortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    if (oldValAsString != newValAsString) {
      this.reloadData();
    }
  }
  @Watch('allocationTaxSettings.searchParams.dataOption.page', { deep: true })
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    if (newVal != oldVal) this.reloadData();
  }

  private onOptionsChanged(newVal: any) {
    this.reloadData();
  }
  async reloadData() {
    await this.getAllocationTaxSettings({
      allocationTaxGroupsId: this.model.id,
      searchParams: this.allocationTaxSettings.searchParams,
    });
  }
  //#endregion

  async mounted() {
    this.reinitImportSettings();
  }

  private async reinitImportSettings() {
    // this.allocationTaxGroupsImportSetting = allocationTaxGroupsImportSetting.parse({
    //   importClearThreshold: -1,
    //   allocationTaxGroupsId: this.model.id,
    //   organisationId: this.organization.id,
    // });
    // if (this.allocationTaxGroupsImportSetting.id)
    //   Object.assign(this.allocationTaxGroupsImportSetting, await this.getAllocationTaxGroupsImportSetting());
    // else if (this.model.id)
    //   Object.assign(
    //     this.allocationTaxGroupsImportSetting,
    //     await this.getAllocationTaxGroupsImportSettingByGroupId(this.model.id)
    //   );
    // this.reloadData();
  }

  handleSelectFile() {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload != null) {
      fileUpload.click();
    }
  }
}
