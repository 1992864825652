import { render, staticRenderFns } from "./tax-group.html?vue&type=template&id=1857e77f&scoped=true&external"
import script from "./tax-group.ts?vue&type=script&lang=ts&external"
export * from "./tax-group.ts?vue&type=script&lang=ts&external"
import style0 from "./tax-group.scss?vue&type=style&index=0&id=1857e77f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1857e77f",
  null
  
)

/* custom blocks */
import block0 from "./tax-group.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Faccount-assignment%2Ftax-groups%2Ftax-group%2Ftax-group.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VCol,VDataTable,VFileInput,VHover,VIcon,VProgressCircular,VRow,VTextField})
