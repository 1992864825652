import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import allocationTaxGroup, { AllocationTaxGroup } from '@/shared/model/allocationTaxGroup';
import allocationTaxGroupsImportSetting, {
  AllocationTaxGroupsImportSetting,
} from '@/shared/model/allocationTaxGroupsImportSetting';
import { AllocationTaxSetting } from '@/shared/model/allocationTaxSetting';
import EditTaxGroupDialog from './edit-tax-group-dialog/edit-tax-group-dialog.vue';
import TaxGroupView from './tax-group/tax-group.vue';
import { Organisation } from '@/shared/model/organisation';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'tax-groups-view';
const logger = new Logger(name);
const allocationTaxGroupModule = namespace('allocationTaxGroup');
const allocationTaxGroupsImportSettingModule = namespace('allocationTaxGroupsImportSetting');
const allocationTaxSettingModule = namespace('allocationTaxSetting');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EditTaxGroupDialog, TaxGroupView } })
export default class TaxGroupsView extends Vue {
  @allocationTaxGroupModule.Action('updateAllocationTaxGroup')
  private updateAllocationTaxGroup!: any;
  @allocationTaxGroupModule.Action('getAllocationTaxGroups')
  private getAllocationTaxGroups!: any;
  @allocationTaxGroupModule.Getter('getAllocationTaxGroups')
  private allocationTaxGroups!: OdataItems<AllocationTaxGroup>;

  @allocationTaxGroupsImportSettingModule.Action('updateAllocationTaxGroupsImportSetting')
  private updateAllocationTaxGroupsImportSetting!: any;
  @allocationTaxGroupsImportSettingModule.Action('getAllocationTaxGroupsImportSettings')
  private getAllocationTaxGroupsImportSettings!: any;
  @allocationTaxGroupsImportSettingModule.Getter('getAllocationTaxGroupsImportSettings')
  private allocationTaxGroupsImportSettings!: OdataItems<AllocationTaxGroupsImportSetting>;

  @allocationTaxSettingModule.Action('getAllocationTaxSettings')
  private getAllocationTaxSettings!: any;
  @allocationTaxSettingModule.Getter('getAllocationTaxSettings')
  private allocationTaxSettings!: OdataItems<AllocationTaxSetting>;

  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('created_at'), value: 'createdAt' },
      { text: this.$t('from'), value: 'fromDateTime' },
      { text: this.$t('to'), value: 'toDateTime' },
    ];
    return headers;
  }
  expanded = [];
  allocationTaxGroup: AllocationTaxGroup = allocationTaxGroup.parse({
    fromDateTime: '',
    toDateTime: '',
  });

  clickNewGroup() {
    this.openDialog(this.allocationTaxGroup);
  }

  async doUpdateAllocationTaxGroup(item: AllocationTaxGroup) {
    item.organisationId = this.organization.id;
    await this.updateAllocationTaxGroup(item).then((x: string) => {});
  }

  //#region Dialog logic
  dialogAddEdit = {
    show: false,
    model: {},
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnUpdate(item: any) {
    await this.doUpdateAllocationTaxGroup(item);
    await this.getAllocationTaxGroups();
    this.dialogAddEdit.show = false;
  }

  private async openDialog(item: any) {
    this.dialogAddEdit.model = { ...item };
    this.dialogAddEdit.show = true;
  }
  //#endregion
  @Watch('allocationTaxGroups.searchParams', { deep: true })
  async onUpdateUsersSearchParams(nwal: any, owal: any) {
    await this.getAllocationTaxGroups();
  }
  async mounted() {
    await this.getAllocationTaxGroups();
  }

  private formatDateTime(item: AllocationTaxGroup) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(item.createdAt ?? item.updatedAt, this.$i18n.locale);
  }
}
